<template>
  <div v-if="!props.from && highlightWhenEmpty" class="lg:absolute lg:top-[50px] w-full rounded-t-xl lg:rounded-t-none lg:rounded-t-none lg:rounded-b-xl bg-cta-50 lg:pb-1.5 pb-3 pt-3 -mb-1 lg:-mt-0.5 text-xs font-bold text-center text-cta-700">
    {{ t('hint') }}
  </div>
  <div class="w-full flex">
    <div class="w-2/4 inline-block">
      <div
        v-if="labelup"
        :class="['cursor-pointer text-base mb-1 block font-semibold']"
      >
        {{ t('from') }}
        <span v-if="props.mandatoryFields">*</span>
      </div>
      <div class="block relative" @click="resetTo(false)">
        <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
          <UiIcon src="/svg/icons/calendar.svg" width="22" height="22" class="fill-neutral-500" />
        </span>
        <div
          :class="[
            { 'pl-11 pr-3 py-3 h-[55px]': normal && labelup},
            { 'py-2': slim && labelup},
            { 'pl-11 pr-3 pt-6 pb-1 h-[55px] text-lg font-semibold peer': normal && !labelup},
            { 'pl-11 pr-6 pt-3 pb-1 text-base font-semibold peer': slim && !labelup},
            { 'border-cta-700 ring-1 ring-cta-700' : normal && !props.from && highlightWhenEmpty},
            { 'border-neutral-200' : ((normal || slim) && props.from) || ((normal || slim) && !highlightWhenEmpty)},
            'border rounded-l-xl bg-white text-left w-full focus:outline-none cursor-pointer active-input'
          ]"
        >
          <div
            v-if="label"
            :class="[
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-100 -translate-y-1': slim && !props.from},
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-[75%] -translate-y-3.5': slim && props.from},
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-100 translate-y-0': normal && !props.from},
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-[85%] -translate-y-2.5': normal && props.from},
              'left-0 cursor-pointer'
            ]"
          >
            {{ t('from') }}
            <span v-if="props.mandatoryFields">*</span>
          </div>
          <div v-if="props.from" class="absolute">
            {{ props.from }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-2/4 inline-block">
      <div
        v-if="labelup"
        :class="['cursor-pointer text-base mb-1 block font-semibold']"
      >
        <UiLanguagePlaceholder domain="UiDaterangeInput" name="to" />
        <span v-if="props.mandatoryFields">*</span>
      </div>
      <div class="block relative" @click="resetTo">
        <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
          <UiIcon src="/svg/icons/calendar.svg" width="22" height="22" class="fill-neutral-500" />
        </span>
        <div
          :class="[
            { 'pl-11 pr-3 py-3 h-[55px]': normal && labelup},
            { 'py-2': slim && labelup},
            { 'pl-11 pr-3 pt-6 pb-1 h-[55px] text-lg font-semibold peer': normal && !labelup},
            { 'pl-11 pr-6 pt-3 pb-1 text-base font-semibold peer': slim && !labelup},
            { 'border-cta-500 ring-1 ring-cta-500' : normal && !props.from && highlightWhenEmpty},
            { 'border-neutral-200' : ((normal || slim) && props.from) || ((normal || slim) && !highlightWhenEmpty)},
            'border border-l-transparent rounded-r-xl bg-white text-left w-full focus:outline-none cursor-pointer active-input'
          ]"
        >
          <div
            v-if="label"
            :class="[
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-100 -translate-y-1': slim && !props.to},
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-[75%] -translate-y-3.5': slim && props.to},
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-100 translate-y-0': normal && !props.to},
              { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform top-4 z-10 origin-[0] left-11 scale-[85%] -translate-y-2.5': normal && props.to},
              'left-0 cursor-pointer'
            ]"
          >
            {{ t('to') }}
            <span v-if="props.mandatoryFields">*</span>
          </div>
          <div v-if="props.to" class="absolute">
            {{ props.to }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <input v-if="props.nameFrom" :name="props.nameFrom" :value="props.from" type="hidden">
  <input v-if="props.nameTo" :name="props.nameTo" :value="props.to" type="hidden">
</template>

<script setup lang="ts">
import UiIcon from '~/components/ui/UiIcon.vue'
const { translate: t } = useTranslations('UiDaterangeInput')

const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  },
  highlightWhenEmpty: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  activeInput: {
    type: Boolean,
    default: false
  },
  placeholderFrom: {
    type: String,
    default: ''
  },
  placeholderTo: {
    type: String,
    default: ''
  },
  nameFrom: {
    type: String,
    default: null
  },
  nameTo: {
    type: String,
    default: null
  },
  from: {
    type: String,
    default: ''
  },
  to: {
    type: String,
    default: ''
  },
  mandatoryFields: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:from', 'update:to', 'updateTemporaryBis'])

// Label choices
const label = computed(() => props.label === 'normal')
const labelup = computed(() => props.label === 'up')

// Design choices
const normal = computed(() => props.design === 'normal')
const slim = computed(() => props.design === 'slim')
function resetTo (status = true) {
  if (status) {
    emit('updateTemporaryBis', null)
    emit('update:to', null)
  } else {
    emit('updateTemporaryBis', props.to)
  }
}
</script>

<style lang="scss" scoped>
 .active .active-input{
   @apply outline-none border-petrol-500 ring-1 ring-petrol-500
 }
</style>
